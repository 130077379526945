import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import sr from '@utils/sr';
import { srConfig } from '@config';
import { Icon } from '@components/icons';
import { usePrefersReducedMotion } from '@hooks';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import '../../styles';
import Projects from './projects';
const StyledProjectsSection = styled.section`
  max-width: unset;
  padding: 100px 150px;
  overflow: hidden;

  @media (max-width: 1024px) {
    padding: 100px 50px;
  }

  @media (max-width: 768px) {
    padding: 70px 30px;
    display: none !important;
  }

  @media (max-width: 480px) {
    padding: 50px 20px;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-slide {
    opacity: 0.5;
    transition: opacity 0.3s ease;
  }

  .slick-slide.slick-active {
    opacity: 1;
  }

  .slick-dots {
    bottom: -40px;
  }
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .slick-list {
    position: relative;
    display: block;
    overflow: unset !important;
    margin: 0;
    padding: 0;
  }

  .slick-dots li button:before {
    color: rgba(45, 212, 191, 0.1) !important;
    opacity: 1 !important;
    font-size: 15px;
  }
  li.slick-active button:before {
    color: var(--green) !important;
    opacity: 1 !important;
    font-size: 15px;
  }
  ul.slick-dots {
    bottom: -11%;
  }
  .swiper-slide {
    width: 100%;
    height: auto;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: var(--green);
  }

  .swiper-pagination-bullet-active {
    background-color: var(--green);
  }
`;

const StyledProject = styled.li`
  position: relative;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;

  .project-content,
  .project-image {
    transition: none;
  }

  @media (max-width: 768px) {
    ${({ theme }) => theme.mixins.boxShadow};
  }

  &:not(:last-of-type) {
    margin-bottom: 100px;

    @media (max-width: 768px) {
      margin-bottom: 70px;
    }

    @media (max-width: 480px) {
      margin-bottom: 30px;
    }
  }

  &:nth-of-type(odd) {
    .project-content {
      text-align: start;

      @media (max-width: 1080px) {
      }
      @media (max-width: 768px) {
        padding: 40px 40px 30px;
        text-align: left;
      }
      @media (max-width: 480px) {
        padding: 25px 25px 20px;
      }
    }
    .project-tech-list {
      justify-content: flex-start;
      gap: 10px;
      @media (max-width: 768px) {
        justify-content: flex-start;
      }

      li {
        margin: 0px;
        white-space: nowrap;

        list-style: none;
        box-sizing: border-box;
        border: 0 solid #e5e7eb;

        display: flex;
        align-items: center;
        border-radius: 9999px;
        background-color: rgba(45, 212, 191, 0.1);
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.25rem;
        color: rgb(94 234 212 / 1);
        font-family: 'SF Mono';
        @media (max-width: 768px) {
          margin: 0 10px 5px 0;
        }
      }
    }
    .project-links {
      justify-content: flex-start;
      margin-left: 0;
      margin-right: -10px;

      @media (max-width: 768px) {
        justify-content: flex-start;
        margin-left: -10px;
        margin-right: 0;
      }
    }
    .project-image {
      @media (max-width: 768px) {
      }
    }
  }
  .project-grid {
    position: relative;
  }

  .project-content {
    position: absolute;
    top: 15%;
    left: 10%;

    @media (max-width: 1080px) {
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 40px 40px 30px;
      z-index: 5;
      position: relative;
      width: 100%;
      margin: auto;
      padding: 0 !important;
    }

    @media (max-width: 480px) {
    }
  }

  .project-overline {
    margin: 10px 0;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
    font-weight: 400;
  }

  .project-title {
    color: var(--lightest-slate);
    font-size: clamp(24px, 5vw, 28px);

    @media (min-width: 768px) {
      margin: 0 0 10px;
    }

    @media (max-width: 768px) {
      color: var(--white);

      a {
        position: static;

        &:before {
          content: '';
          display: block;
          position: absolute;
          z-index: 0;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .project-description {
    ${({ theme }) => theme.mixins.boxShadow};
    transition: var(--transition);
    backdrop-filter: blur(10px);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.05);
    border-image: initial;
    border-radius: 1rem;
    width: 100%;
    position: relative;
    transition: all 0.2s ease-out 0s;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
    min-height: 0px;
    margin-bottom: 2rem;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    z-index: 2;
    padding: 25px;
    background-color: rgba(30, 41, 59, 0.5);
    color: var(--light-slate);
    font-size: var(--fz-lg);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px, rgba(0, 0, 0, 0) 0px 0px,
      rgba(148, 163, 184, 0.1) 0px 1px 0px 0px inset;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale)
      var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia)
      drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
    max-width: 400px;
    @media (max-width: 768px) {
      width: unset;
      height: unset;
      max-width: 768px;
      &:hover {
        box-shadow: none;
      }
    }

    a {
      ${({ theme }) => theme.mixins.inlineLink};
      color: #ccd6f6 !important;
    }

    strong {
      color: var(--white);
      font-weight: normal;
    }
  }

  .project-tech-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    margin: 15px 0 0px;
    padding: 0;
    list-style: none;

    li {
      margin: 0px 10px 10px 0px;
      white-space: nowrap;

      list-style: none;
      box-sizing: border-box;
      border: 0 solid #e5e7eb;

      display: flex;
      align-items: center;
      border-radius: 9999px;
      background-color: rgba(45, 212, 191, 0.1);
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.25rem;
      color: rgb(94 234 212 / 1);
      font-family: 'SF Mono';
    }

    @media (max-width: 768px) {
      margin: 10px 0;

      li {
        margin: 0 10px 5px 0;
        color: var(--lightest-slate);
      }
    }
  }

  .project-links {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    margin-top: -7px;
    margin-left: -10px;
    color: var(--lightest-slate);

    a {
      ${({ theme }) => theme.mixins.flexCenter};

      &.external {
        svg {
          width: 22px;
          height: 22px;
          margin-top: 4px;
          margin-left: 3px;
        }
      }

      svg {
        width: 22px;
        height: 22px;
      }
    }

    .cta {
      ${({ theme }) => theme.mixins.smallButton};
      margin: 10px;
    }
  }

  .project-image {
    ${({ theme }) => theme.mixins.boxShadow};
    box-shadow: 0 10px 30px -15px var(--navy-shadow);
    position: relative;
    z-index: 1;
    border-radius: 1rem;
    overflow: hidden;
    transition: all 0.25s ease-out 0s;
    transform-style: preserve-3d;
    perspective: 1000px;
    width: 80%;
    margin-left: auto;
    transition: transform 0.1s ease-out;
    .gatsby-image-wrapper {
      transition: all 0.25s ease-out;
      border-radius: var(--border-radius);
      box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
    }
    &:hover {
      transform: scale(1.05);
      box-shadow: 0 20px 30px -15px rgba(2, 12, 27, 0.7);
    }

    @media (max-width: 768px) {
      height: 100%;
      z-index: 120;
    }

    a {
      width: 100%;
      height: 100%;
      background-color: var(--green);
      border-radius: var(--border-radius);
      vertical-align: middle;

      &:hover,
      &:focus {
        background: transparent;
        outline: 0;

        &:before,
        .img {
          background: transparent;
          filter: none;
        }
      }

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        transition: var(--transition);
        background-color: var(--navy);
        mix-blend-mode: screen;
      }
    }

    .img {
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1) brightness(90%);
      height: 72vh;
      display: flex;
      @media (max-width: 768px) {
        object-fit: cover;
        width: auto;
        height: 100%;
        /* Remove or adjust the filter properties as needed */
        filter: none; /* or any other filter values */
      }
    }
  }
`;

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '100px',
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        centerPadding: '50px',
      },
    },
    {
      breakpoint: 768,
      settings: {
        centerMode: false,
        centerPadding: '0px',
      },
    },
    {
      breakpoint: 480,
      settings: {
        centerMode: false,
        centerPadding: '0px',
      },
    },
  ],
};
const Featured = () => {
  const data = useStaticQuery(graphql`
    {
      featured: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/featured/" } }
        sort: { fields: [frontmatter___date], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              cover {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
              tech
              github
              external
              cta
            }
            html
          }
        }
      }
    }
  `);

  const featuredProjects = data.featured.edges.filter(({ node }) => node);
  const revealTitle = useRef(null);
  const revealProjects = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  const handleMouseMove = (e, imageElement) => {
    const { left, top, width, height } = imageElement.getBoundingClientRect();
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;

    const rotateX = 10 * (y - 0.5);
    const rotateY = 10 * (0.5 - x);

    requestAnimationFrame(() => {
      imageElement.style.transform = `
        perspective(1000px) 
        rotateX(${rotateX}deg) 
        rotateY(${rotateY}deg) 
        scale3d(1.05, 1.05, 1.05)
      `;
    });
  };

  const debouncedHandleMouseMove = debounce(handleMouseMove, 5);

  const handleMouseLeave = imageElement => {
    requestAnimationFrame(() => {
      imageElement.style.transform = 'perspective(1000px) rotateX(0) rotateY(0) scale3d(1, 1, 1)';
    });
  };

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const truncateDescription = (description, wordLimit = 30) => {
    const words = description.split(' ');
    if (words.length > wordLimit) {
      return `${words.slice(0, wordLimit).join(' ')}...`;
    }
    return description;
  };
  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  return (
    <>
      <div id="projects">
        <StyledProjectsSection>
          <h2 className="numbered-heading" ref={revealTitle}>
            Projects I Built Recently.
          </h2>

          <Slider {...sliderSettings}>
            {featuredProjects &&
              featuredProjects.map(({ node }, i) => {
                const { frontmatter, html } = node;
                const { external, title, tech, github, cover, cta } = frontmatter;
                return (
                  <StyledProject key={i} ref={el => (revealProjects.current[i] = el)}>
                    <div className="project-grid">
                      <div className="project-content">
                        <div>
                          <div className="project-description">
                            <h3 className="project-title">
                              <a href={external}>{title}</a>
                            </h3>
                            <div dangerouslySetInnerHTML={{ __html: truncateDescription(html) }} />
                            {tech.length > 0 && (
                              <ul className="project-tech-list">
                                {tech.map((tech, i) => (
                                  <li key={i}>{tech}</li>
                                ))}
                                <div className="project-links">
                                  {cta && (
                                    <a href={cta} aria-label="Course Link" className="cta">
                                      Learn More
                                    </a>
                                  )}
                                  {github && (
                                    <a href={github} aria-label="GitHub Link">
                                      <Icon name="GitHub" />
                                    </a>
                                  )}
                                  {external && !cta && (
                                    <a
                                      href={external}
                                      aria-label="External Link"
                                      className="external">
                                      <Icon name="External" />
                                    </a>
                                  )}
                                </div>
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="project-image"
                        onMouseMove={e => debouncedHandleMouseMove(e, e.currentTarget)}
                        onMouseLeave={e => handleMouseLeave(e.currentTarget)}>
                        <a href={external} target="_blank" rel="noreferrer">
                          <div className="gatsby-image-wrapper gatsby-image-wrapper-constrained img">
                            <div style={{ maxWidth: '700px', display: 'block' }}>
                              <img
                                src={cover.publicURL}
                                alt=""
                                style={{
                                  transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)',
                                  objectFit: 'cover',
                                  objectPosition: 'center',
                                  transform: 'translateZ(50px)',
                                }}
                              />
                            </div>
                            <img
                              src={cover.publicURL}
                              alt=""
                              style={{
                                transition: 'opacity 500ms linear 0s',
                                objectFit: 'cover',
                                objectPosition: 'top',
                              }}
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                  </StyledProject>
                );
              })}
          </Slider>
        </StyledProjectsSection>
        <Projects />
      </div>
    </>
  );
};

export default Featured;
