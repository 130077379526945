import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Letter A Logo</title>
    <g>
      <path stroke="currentColor" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" d="M50,5 L11,27 L11,72 L50,95 L89,72 L89,27 z" fill="none" />
      <path d="M30,65 L50,25 L70,65 L66,65 L50,35 L34,65 z" fill="currentColor" />
    </g>
  </svg>

);

export default IconLoader;
