import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import styled from 'styled-components';

const StyledContactSection = styled.section`
  max-width: 600px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  input,
  textarea {
    padding: 0.95rem;
    background: transparent;
    backdrop-filter: blur(10px);
    background-color: rgba(30, 41, 59, 0.5);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px, rgba(0, 0, 0, 0) 0px 0px,
      rgba(148, 163, 184, 0.1) 0px 1px 0px 0px inset;
    border-radius: 8px;
    color: #eee;
    border: 0px solid rgb(229, 231, 235);
    display: flex;
    align-items: center;
    resize: unset;
  }

  .form-row {
    display: flex;
    gap: 1rem;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  label {
    display: none;
  }

  button {
    align-self: flex-start;
    padding: 0.75rem 1.5rem;
    background-color: #000;
    color: #fff;
    border: none;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    color: var(--green);
    background-color: transparent;
    border: 1px solid var(--green);
    border-radius: var(--border-radius);
    padding: 0.75rem 1rem;
    font-family: var(--font-mono);
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    transition: var(--transition);
    font-size: var(--fz-xs);
  }

  input::placeholder,
  textarea::placeholder {
    color: #ccd6f6;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  &:before {
    position: relative;
    bottom: 0px;
    counter-increment: section;
    content: '0' counter(section) '.';
    margin-right: 10px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-md), 3vw, var(--fz-xl));
    font-weight: 400;

    @media (max-width: 480px) {
      margin-bottom: -3px;
      margin-right: 5px;
    }
  }
`;
const Notification = styled.div`
  padding: 1rem;
  margin-top: 1rem;
  background-color: #4caf50;
  color: white;
  border-radius: 4px;
  text-align: center;
`;

const Contact = () => {
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
  });
  const [notification, setNotification] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = e => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .send(
        'service_i21drx7',
        'template_q0t1xgh',
        {
          from_name: `${formState.firstName} ${formState.lastName}`,
          from_email: formState.email,
          subject: formState.subject,
          message: formState.message,
        },
        'ImUIPr-lQd-OCvbBu',
      )
      .then(
        () => {
          setNotification('Message sent successfully!');
          setFormState({
            firstName: '',
            lastName: '',
            email: '',
            subject: '',
            message: '',
          });
          setLoading(false);
          setTimeout(() => setNotification(''), 5000);
        },
        () => {
          setNotification('Failed to send message. Please try again.');
          setLoading(false);
          setTimeout(() => setNotification(''), 5000);
        },
      );
  };

  return (
    <StyledContactSection id="contact">
      <Title>LET'S BUILD A THING</Title>
      <StyledForm onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder="FIRST NAME *"
              required
              onChange={handleChange}
              value={formState.firstName}
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              placeholder="LAST NAME *"
              required
              onChange={handleChange}
              value={formState.lastName}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="EMAIL *"
              required
              onChange={handleChange}
              value={formState.email}
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            id="subject"
            name="subject"
            placeholder="SUBJECT *"
            required
            onChange={handleChange}
            value={formState.subject}
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            placeholder="MESSAGE *"
            required
            onChange={handleChange}
            value={formState.message}
            rows="6"></textarea>
        </div>
        <button disabled={loading} type="submit">
          {loading ? 'Sending...' : 'SEND IT'}
        </button>
      </StyledForm>
      {notification && <Notification>{notification}</Notification>}
    </StyledContactSection>
  );
};

export default Contact;
