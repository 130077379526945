import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledProjectsSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: clamp(24px, 5vw, var(--fz-heading));
  }

  .archive-link {
    font-family: var(--font-mono);
    font-size: var(--fz-sm);
    &:after {
      bottom: 0.1em;
    }
  }

  .projects-grid {
    ${({ theme }) => theme.mixins.resetList};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    position: relative;
    margin-top: 50px;

    @media (max-width: 1080px) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }

  .more-button {
    ${({ theme }) => theme.mixins.button};
    margin: 80px auto 0;
  }
`;

const StyledProject = styled.li`
  position: relative;
  cursor: default;
  transition: var(--transition);

  @media (prefers-reduced-motion: no-preference) {
    &:hover,
    &:focus-within {
      .project-inner {
        transform: translateY(-7px);
      }
    }
  }

  a {
    position: relative;
    z-index: 1;
  }

  .project-inner {
    ${({ theme }) => theme.mixins.boxShadow};
    ${({ theme }) => theme.mixins.flexBetween};
    box-shadow: 0 10px 30px -15px var(--navy-shadow);
    display: flex;
    -webkit-box-align: center;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 100%;
    -webkit-box-pack: start;
    justify-content: start;
    border-radius: 1rem;
    background-color: var(--light-navy);
    transition: var(--transition);
    overflow: auto;
    background-color: rgba(45, 212, 191, 0.1);
    backdrop-filter: blur(5px);
  }
  .project-bottom {
    padding: 1rem 1rem;
  }
  .project-top {
    ${({ theme }) => theme.mixins.flexBetween};
    margin-bottom: 35px;

    .folder {
      color: var(--green);
      svg {
        width: 40px;
        height: 40px;
      }
    }

    .project-links {
      display: flex;
      align-items: center;
      margin-right: -10px;
      color: var(--light-slate);

      a {
        ${({ theme }) => theme.mixins.flexCenter};
        padding: 5px 7px;

        &.external {
          svg {
            width: 22px;
            height: 22px;
            margin-top: -4px;
          }
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .project-image {
    ${({ theme }) => theme.mixins.boxShadow};
    grid-column: 6 / -1;
    grid-row: 1 / -1;
    position: relative;
    z-index: 1;

    @media (max-width: 768px) {
      grid-column: 1 / -1;
      height: 100%;
    }

    a {
      width: 100%;
      height: 100%;
      background-color: var(--green);
      border-radius: var(--border-radius);
      vertical-align: middle;

      &:hover,
      &:focus {
        background: transparent;
        outline: 0;

        &:before,
        .img {
          background: transparent;
          filter: none;
        }
      }

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        transition: var(--transition);
        background-color: var(--navy);
        mix-blend-mode: screen;
      }
    }

    .img {
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      /* Remove or adjust the filter properties as needed */
      filter: grayscale(100%) contrast(1) brightness(90%); /* or any other filter values */

      @media (max-width: 768px) {
        object-fit: cover;
        width: auto;
        height: 100%;
        /* Remove or adjust the filter properties as needed */
        filter: none; /* or any other filter values */
      }
    }
  }
  .project-title {
    margin: 0 0 10px;
    color: var(--lightest-slate);
    font-size: var(--fz-xxl);

    a {
      position: static;

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  .project-description {
    color: var(--light-slate);
    font-size: 17px;

    a {
      ${({ theme }) => theme.mixins.inlineLink};
    }
  }

  .project-tech-list {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0;
    margin: 0px 0 0 0;
    list-style: none;

    li {
      font-family: var(--font-mono);
      font-size: var(--fz-xxs);
      line-height: 1.75;
      font-family: var(--font-mono);
      font-size: var(--fz-xxs);
      line-height: 1.75;
      margin: 0px 10px 10px 0px;
      white-space: nowrap;
      list-style: none;
      box-sizing: border-box;
      border: 0px solid rgb(229, 231, 235);
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      border-radius: 9999px;
      background-color: rgba(45, 212, 191, 0.1);
      padding: 0.25rem 0.75rem;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.25rem;
      color: rgb(94, 234, 212);
      font-family: 'SF Mono';
      margin-right: 0;
      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }
  }
`;
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
const Projects = () => {
  const data = useStaticQuery(graphql`
    query {
      projects: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/content/projects/" }
          frontmatter: { showInProjects: { ne: false } }
        }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              tech
              github
              external
              image
            }
            html
          }
        }
      }
    }
  `);

  const [showMore, setShowMore] = useState(false);
  const revealTitle = useRef(null);
  const revealArchiveLink = useRef(null);
  const revealProjects = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  const size = useWindowSize();
  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    sr.reveal(revealArchiveLink.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const GRID_LIMIT = 6;
  const projects = data.projects.edges.filter(({ node }) => node);
  const firstSix = projects.slice(0, GRID_LIMIT);
  const projectsToShow = showMore ? projects : firstSix;
  // const getUniqueTechnologies = () => {
  //   const technologies = new Set();
  //   projects.forEach(({ node }) => {
  //     node.frontmatter.tech.forEach(tech => technologies.add(tech));
  //   });
  //   return Array.from(technologies);
  // };

  // State variable to store the selected technology filter
  const [selectedTech, setSelectedTech] = useState('All');
  useEffect(() => {
    setSelectedTech('All');
  }, []);

  // Filter the projects based on the selected technology
  const filteredProjects = projectsToShow.filter(({ node }) =>
    selectedTech === 'All' ? true : node.frontmatter.tech.includes(selectedTech),
  );
  const projectInner = node => {
    const { frontmatter } = node;
    const { external, title, tech, image } = frontmatter;

    return (
      <div className="project-inner" id="projects2">
        <header>
          {/* <div className="project-top">
            <div className="folder">
              <Icon name="Folder" />
            </div>
            <div className="project-links">
              {github && (
                <a href={github} aria-label="GitHub Link" target="_blank" rel="noreferrer">
                  <Icon name="GitHub" />
                </a>
              )}
              {external && (
                <a
                  href={external}
                  aria-label="External Link"
                  className="external"
                  target="_blank"
                  rel="noreferrer">
                  <Icon name="External" />
                </a>
              )}
            </div>
          </div> */}
          <div className="project-image">
            <a href="/">
              <div className="gatsby-image-wrapper gatsby-image-wrapper-constrained img">
                <div style={{ maxWidth: '700px', display: 'block' }}>
                  <img
                    src="data:image/svg+xml;charset=utf-8,%3Csvg height='438' width='700' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E"
                    style={{ maxWidth: '100%', display: 'block', position: 'static' }}
                    alt=""
                  />
                </div>
                <img
                  src={image}
                  alt=""
                  style={{ transition: 'opacity 500ms linear 0s', objectFit: 'cover' }}
                />
              </div>
            </a>
          </div>
          {/* <div className="project-description" dangerouslySetInnerHTML={{ __html: html }} /> */}
        </header>
        <div className="project-bottom">
          <h3 className="project-title">
            <a href={external} target="_blank" rel="noreferrer">
              {title}
            </a>
          </h3>
          <footer>
            {tech && (
              <ul className="project-tech-list">
                {tech.map((tech, i) => (
                  <li key={i}>{tech}</li>
                ))}
              </ul>
            )}
          </footer>
        </div>
      </div>
    );
  };

  return (
    <StyledProjectsSection>
      <h2 ref={revealTitle} className={size.width > 768 ? 'n' : 'numbered-heading'}>
        {size.width > 768 ? 'More Projects I have built' : '  Projects I Built Recently.'}
      </h2>
      {/* <Link className="inline-link archive-link" to="/archive" ref={revealArchiveLink}>
        view the archive
      </Link> */}

      {/* Add filter buttons or a dropdown to select a technology to filter projects */}
      {/* <div>
        <button
          style={{
            padding: '10px',
            background: 'transparent',
            fontFamily: '\'SF Mono\'',
            color: '#ccd6f6',
            fontSize: '13px',
          }}
          onClick={() => setSelectedTech('All')}>
          All
        </button>
        {getUniqueTechnologies().map(tech => (
          <button
            style={{
              padding: '10px',
              background: 'transparent',
              fontFamily: '\'SF Mono\'',
              color: '#ccd6f6',
              fontSize: '13px',
            }}
            key={tech}
            onClick={() => setSelectedTech(tech)}>
            {tech}
          </button>
        ))}
      </div> */}

      <ul className="projects-grid">
        {/* Filter projects based on the selected technology */}
        {prefersReducedMotion ? (
          <>
            {filteredProjects &&
              filteredProjects.map(({ node }, i) => (
                <StyledProject key={i}>{projectInner(node)}</StyledProject>
              ))}
          </>
        ) : (
          <TransitionGroup component={null}>
            {filteredProjects &&
              filteredProjects.map(({ node }, i) => (
                <CSSTransition
                  key={i}
                  classNames="fadeup"
                  timeout={i >= GRID_LIMIT ? (i - GRID_LIMIT) * 300 : 300}
                  exit={false}>
                  <StyledProject
                    key={i}
                    ref={el => (revealProjects.current[i] = el)}
                    style={{
                      transitionDelay: `${i >= GRID_LIMIT ? (i - GRID_LIMIT) * 100 : 0}ms`,
                    }}>
                    {projectInner(node)}
                  </StyledProject>
                </CSSTransition>
              ))}
          </TransitionGroup>
        )}
      </ul>

      <button className="more-button" onClick={() => setShowMore(!showMore)}>
        Show {showMore ? 'Less' : 'More'}
      </button>
    </StyledProjectsSection>
  );
};

export default Projects;
