import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';
import { StaticImage } from 'gatsby-plugin-image';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  height: 100vh;
  padding: 0;

  @media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
    height: auto;
    padding-top: var(--nav-height);
  }
  .btn-container {
    display: flex;
    align-items: center;
    margin-top: 50px;
    @media (max-width: 700px) {
      flex-direction: column;
      justify-content: start;
      align-items: start;
    }
  }
  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 5px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 650px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
  .email-link-two {
    color: var(--green);
    background-color: transparent;
    border: 1px solid var(--green);
    border-radius: var(--border-radius);
    padding: 0.5rem 1.75rem;
    font-size: var(--fz-sm);
    font-family: var(--font-mono);
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    transition: var(--transition);
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    gap: 6px;
    &:hover,
    &:focus,
    &:active {
      background-color: var(--green-tint);
      outline: none;
    }
    &:after {
      display: none !important;
    }
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1>Hi, my name is</h1>;
  const two = <h2 className="big-heading">Ali Ghauri.</h2>;
  const three = <h3 className="big-heading">Building AI-powered digital experiences.</h3>;
  const four = (
    <p>
      I'm a <a href="mern">Full Stack Developer</a> excelling in AI and Software Development. With a
      passion for creating exceptional digital experiences, I transform visions into accessible,
      user-friendly web applications, ensuring seamless interactions at every touchpoint.
    </p>
  );
  const five = (
    <div className="btn-container">
      <a
        className="email-link"
        style={{ marginRight: 15 }}
        href="https://www.linkedin.com/in/alighauri/"
        target="_blank"
        rel="noreferrer">
        Let's Connect!
      </a>
      <a
        className="email-link-two"
        href="https://www.upwork.com/freelancers/~012e8179b0ccf87c3e"
        target="_blank"
        rel="noreferrer">
        Work with me!{' '}
        <StaticImage
          className="img"
          style={{ objectFit: 'contain', width: 40, height: 40 }}
          src={'./upwork.png'}
          width={20}
          quality={100}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Headshot"
        />
      </a>
    </div>
  );

  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        items.map((item, i) => <div key={`item-${i}`}>{item}</div>)
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={`item-${i}`} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
